<template>
    <!-- info -->
    <section class="info-area">
        <div class="container container-xl">
            <div class="row">
                <div class="col-lg-9 col-md-8 col-12">
                    <div class="info-inner">
                        <div class="row no-gutters">
                            <div class="col-lg-4 col-md-4 col-12">
                                <div class="info-bg h-100">
                                </div>
                            </div>
                            <div class="col-lg-8 col-md-8 col-12">
                                <div class="info-content">
                                    <h5><span v-html="title"></span></h5>
                                    <a href="services.html">Bekijk al onze diensten</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3 col-md-4 col-12">
                    <div class="info-founder">
                        <i class="far fa-building"></i>
                        <p class="highlight">10+</p>
                        <p>jaar <br>ervaring</p>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
  name: 'InfoSlider',
  props: {
      title: String, 
      link: String,
  }
}
</script>