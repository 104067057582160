<template>
    <!-- text -->
    <section class="text-area section-padding">
        <div class="container container-xl">
            <TextContent/>
        </div>
    </section>
</template>

<script>
    import TextContent from '@/components/TextContent.vue'

    export default {
        components: {
            TextContent
        },
        name: 'TextWidget',
    }

</script>