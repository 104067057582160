<template>
  <div id="app">
    <header class="container-fluid w-100 position-relative" :class="[currentPage.includes('Home') ? bgClass : 'bg-transparent']">
      <div class="container container-xl h-100">
        <b-navbar toggleable="lg"  :class="[currentPage.includes('Home') ? bgClass : 'bg-transparent']">
          <b-navbar-brand href="#"><router-link to="/">Peeters Interiors</router-link></b-navbar-brand>

          <b-navbar-toggle target="nav-collapse">
            <template #default="{ expanded }">
              <b-icon v-if="expanded" icon="x" @click="toggleBodyClass('removeClass', 'noScroll')"></b-icon>
              <b-icon v-else icon="text-right" @click="toggleBodyClass('addClass', 'noScroll')"></b-icon>
            </template>
          </b-navbar-toggle>

          <b-collapse id="nav-collapse" is-nav>

            <!-- Right aligned nav items -->
            <b-navbar-nav class="ml-auto" :class="[currentPage.includes('Home') ? bgClass : 'bg-transparent']">
              <b-nav-item href="#"><router-link to="/">Home</router-link></b-nav-item>
              <b-nav-item href="#"><router-link to="/portfolio">Portfolio</router-link></b-nav-item>
              <b-nav-item href="#"><router-link to="/diensten">Diensten</router-link></b-nav-item>
              <b-nav-item href="#">Contact</b-nav-item>
            </b-navbar-nav>

          </b-collapse>
        </b-navbar>
      </div>
      
    </header>
    <!-- header -->
    <router-view />
    <nprogress-container /> 
  </div>
</template>


<script>
  import Vue from 'vue'
  import NprogressContainer from 'vue-nprogress/src/NprogressContainer'
  import '@/assets/css/peeters.css'

  import 'bootstrap'
  import 'bootstrap/dist/css/bootstrap.min.css'
  import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
  import '@fortawesome/fontawesome-free/css/all.css'
  import '@fortawesome/fontawesome-free/js/all.js'



  // Install BootstrapVue
  Vue.use(BootstrapVue)
  // Optionally install the BootstrapVue icon components plugin
  Vue.use(IconsPlugin)

  export default {
    components: {
      NprogressContainer,
    },
    computed: {
      currentPage() {
        return this.$route.name;
      },
    },
    data() {
      return {
          bgClass: 'bg-white',
      };
    },
    methods: {
        toggleBodyClass(addRemoveClass, className) {
        const el = document.body;

        if (addRemoveClass === 'addClass') {
          el.classList.add(className);
        } else {
          el.classList.remove(className);
        }
      },
    },
    // for seo 
     metaInfo() {
        return { 
            title: this.$appName+" | Interieurontwerp & Advies",
            meta: [
                { name: 'description', content:  'Epiloge is about connecting in your field of interest. Our vision is to help people share their knowledge, work, projects, papers and ideas and build their network through what they do rather where they live, study or work.'},
                { property: 'og:title', content: "Epiloge - Build your network in your field of interest"},
                { property: 'og:site_name', content: 'Epiloge'},
                {property: 'og:type', content: 'website'},    
                {name: 'robots', content: 'index,follow'} 
            ]
        }
      }
  }
  
</script>