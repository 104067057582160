<template>
    <agile :navButtons="false" :speed="3000" :dots="false" :fade="true" :autoplay="true" :infinite="true"
        :autoplay-speed="5000">
        <div class="slide">
            <img
                src="https://images.pexels.com/photos/276724/pexels-photo-276724.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940" />
        </div>

        <div class="slide">
            <img
                src="https://images.pexels.com/photos/279719/pexels-photo-279719.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940" />
        </div>
    </agile>
</template>


<script>
    import {
        VueAgile
    } from 'vue-agile'

    export default {
        name: 'HomeSlider',
        components: {
            agile: VueAgile
        }
    }
</script>

<style scoped>
    .agile {
        position: relative;
        overflow: hidden;
        width: 100%;
        max-width: 1920px;
        height: 50vh !important;
        background-color: #999;
        margin: auto;
    }

    .agile__slides {
        height: 100%;
    }

    .agile__slides img {
        height: 50vh !important;
        width: 100%;
        object-fit: cover;
    }
</style>
