<template>
    <!-- featured portfolio -->
    <section class="portfolio-area section-padding">
        <div class="container container-xl">
            <div class="row d-flex flex-column align-items-center">
                <h5>{{subTitle}}</h5>
                <h2 v-html="title" class="text-center px-5 px-md-0"></h2>
            </div>
        </div>
        <div class="container-fluid">
            <div class="row">
                <router-link to="/portfolio" class="col-lg-6 col-md-6 col-12 pb-4" v-for="project in projects" :key="project.id">
                    <div class="portfolio-item" style="background-image: url('https://cdn.pixabay.com/photo/2016/06/24/10/47/architecture-1477041_960_720.jpg')">
                        <div class="portfolio-content">
                            <h4 class="portfolio-title text-dark">{{project.projectName}}</h4>
                            <div class="portfolio-category text-dark">{{project.projectCat}}</div>
                        </div>
                    </div>
                </router-link>
            </div>
        </div>
        <div class="container container-xl pt-4" v-if="countProjects.length >= 4">
            <div class="row d-flex flex-column align-items-center">
                <router-link to="/portfolio" class="main-btn">Alle projecten <i class="fas fa-arrow-right"></i></router-link>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: "FeaturedPortfolio",
    data() {
        return {
            title: "Projecten waar we <b>trots</b> op zijn",
            subTitle: "Uitgelichte Projecten",
            projects: [
                {   
                    projectId: 1,
                    projectName: "Project name 1",
                    projectCat: "The project category",
                    projectImg: "https://cdn.pixabay.com/photo/2016/06/24/10/47/architecture-1477041_960_720.jpg",
                },
                {   
                    projectId: 2,
                    projectName: "Project name 2",
                    projectCat: "The project category",
                    projectImg: "https://cdn.pixabay.com/photo/2016/06/24/10/47/architecture-1477041_960_720.jpg",
                },
                {   
                    projectId: 3,
                    projectName: "Project name 3",
                    projectCat: "The project category",
                    projectImg: "https://cdn.pixabay.com/photo/2016/06/24/10/47/architecture-1477041_960_720.jpg",
                },
                {   
                    projectId: 4,
                    projectName: "Project name 4",
                    projectCat: "The project category",
                    projectImg: "https://cdn.pixabay.com/photo/2016/06/24/10/47/architecture-1477041_960_720.jpg",
                },
            ]
        }
    },
    // computed function if there are more then 4 project show more button is visible
    computed: {
        countProjects() {
            return this.projects.filter(project => project.projectId);
        }
    }
}
</script>