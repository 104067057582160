<template>
    <!-- contact -->
    <section class="contact-area section-padding" v-bind:style="{backgroundColor}">
        <div class="container container-xl">
            <div class="row">
                <div class="col-lg-6 col-md-6 col-12">
                    <div class="contact-info">
                        <div class="section-title">
                            <h6><span v-if="fname">Hi {{fname}}</span> <span v-else>Contact</span></h6>
                            <h2>Get in <b>Touch</b> with Peeters Interiors</h2>
                        </div>
                        <p>If you have a contact Lorem ipsum dolor sit amet cum maiores, excepturi fuga, est doloremque praesentium!</p>
                        <div class="contact-content d-none d-md-block">
                            <div class="contact-inner">
                                <i class="fas fa-map-marker-alt"></i>
                                <div class="d-flex flex-column contact-details">
                                    <h6>Bezoekadres</h6>
                                    <p>Spijndhof 7, Eindhoven</p>
                                </div>
                            </div>
                            <div class="contact-inner">
                                <i class="fas fa-envelope"></i>
                                <div class="d-flex flex-column contact-details">
                                    <h6>Emailadres</h6>
                                    <p>hello@peeterinterior.nl</p>
                                </div>
                            </div>
                            <div class="contact-inner">
                                <i class="fas fa-phone-alt"></i>
                                <div class="d-flex flex-column contact-details">
                                    <h6>Telefoon</h6>
                                    <p>040 - 12345677</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6 col-12">
                    <div class="contact-form">
                        <form action="#" @submit="checkForm" method="post">
                            <div class="row">
                                <div class="alert alert-warning ml-3" v-if="errors.length">
                                    <b>De volgende velden zijn niet juist of correct ingevuld</b>
                                    <ul>
                                    <li v-for="error in errors" :key="error">{{ error }}</li>
                                    </ul>
                                </div>
                                <div class="col-lg-6 col-md-12 col-12 mb-2">
                                    <input type="text" placeholder="Uw naam" id="fname" name="fname" v-model="fname">
                                </div>
                                <div class="col-lg-6 col-md-12 col-12 mb-2">
                                    <input type="email" id="emailadres" name="emailadres" v-model="emailadres" placeholder="Emailadres">
                                </div>
                                <div class="col-lg-6 col-md-12 col-12 mb-2">
                                    <input type="tel" placeholder="Telefoonnummer">
                                </div>
                                <div class="col-lg-6 col-md-12 col-12 mb-2">
                                    <input type="text" placeholder="Onderwerp">
                                </div>
                                <div class="col-lg-12 col-md-12 col-12 mb-2">
                                    <textarea name="message" id="message" cols="30" rows="10" placeholder="Write Message"></textarea>
                                </div>
                                <div class="col-lg-6 col-md-12 col-12">
                                    <button class="main-btn">Verzenden</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    export default {
        name: 'ContactForm',
        props: ['value'],
        computed: {
            inputVal: {
            get() {
                return this.value;
            },
            set(val) {
                this.$emit('input', val);
            }
            }
        },
          data() {
            return {
                errors: [],
                fname: null, 
                emailadres: null,
                message: null,
                backgroundColor: "#ffffff !important",
            }
        },
        methods: {
            checkForm: function(e) {
                if(this.fname && this.emailadres && this.message) {
                    return true;
                }

                this.errors = [];

                if(!this.fname) {
                    this.errors.push('Uw naam is verplicht');
                }

                if(!this.emailadres) {
                    this.errors.push('Uw emailadres is verplicht');
                }

                if(!this.message){
                    this.errors.push('Het bericht veld is verplicht');
                }

                e.preventDefault();
            }
        }
    }
</script>