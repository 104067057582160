import Vue from 'vue'
import App from './App.vue'
import router from './router'
import NProgress from 'vue-nprogress'
import VueMeta from 'vue-meta'

Vue.use(VueMeta)
Vue.use(NProgress)

Vue.prototype.$appName = 'Peeters Interiors'

const nprogress = new NProgress()

Vue.config.productionTip = false

new Vue({
  router,
  nprogress,
  render: h => h(App)
}).$mount('#app')
