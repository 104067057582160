<template>
    <footer class="footer-area">
        <div class="container container-xl py-5">
            <div class="row">
                <!-- col -->
                <div class="col-lg-3 col-md-6 col-12">
                    <h3>Peeters Interiors |</h3>
                    <p class="py-3">Al meer dan 10 jaar ervaring in interieurdesign.
                        Samen bouwen wij naar kwaliteit, sfeer en prachtige projecten.
                    </p>
                    <ul class="list-unstyled d-flex justify-content-between w-50 social-media">
                        <li><a href="#"><i class="fab fa-facebook-square"></i></a></li>
                        <li><a href="#"><i class="fab fa-instagram-square"></i></a></li>
                        <li><a href="#"><i class="fab fa-pinterest-square"></i></a></li>
                    </ul>
                </div>
                <!-- col -->
                <div class="col-lg-3 col-md-6 col-12 px-3 px-lg-5 py-4 py-lg-0">
                    <h6>Sitemap</h6>
                    <ul class="list-unstyled sitemap">
                        <li>
                            <a href="#">About Us</a>
                        </li>
                        <li>
                            <a href="#">About Us</a>
                        </li>
                        <li>
                            <a href="#">About Us</a>
                        </li>
                        <li>
                            <a href="#">About Us</a>
                        </li>
                    </ul>
                </div>
                <!-- col -->
                <div class="col-lg-3 col-md-6 col-12 py-4 py-lg-0">
                    <h6>Contact</h6>
                    <div class="row">
                        <div class="col-12">
                            <ul class="list-unstyled contact-list">
                                <li>
                                    <i class="far fa-clock"></i><span class="pl-2">Ma - Vr 08:30 - 17:30</span>
                                </li>
                                <li>
                                    <i class="fas fa-map-marker-alt"></i><span class="pl-2">Spijndhof 7, Eindhoven</span>
                                </li>
                                <li>
                                    <i class="fas fa-phone-alt"></i><span class="pl-2">040 - 12345677</span>
                                </li>
                                <li>
                                    <i class="fas fa-envelope"></i><span class="pl-2">hello@peetersinteriors.nl</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <!-- col -->
                <div class="col-lg-3 col-md-6 pt-0 pt-md-4 pt-lg-0">
                    <div class="subscribe-form">
                        <h6>Nieuwsbrief</h6>
                        <form action="index.html">
                            <input type="email" placeholder="Uw Emailadres">
                            <button class="main-btn w-100 mt-2">Inschrijven</button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </footer>
</template>

<script>
export default {
    name: 'FooterPeeters',
}
</script>