<template>
    <div>
        <article v-for="post in posts" :key="post.id" class="row py-5">
            <div class="col-lg-6 col-md-12 col-sm-12">
                <div class="about-bg">
                    <img v-if="post.img.image" :src="post.img.image" :alt="post.img.alt" />
                </div>
            </div>
            <div class="col-lg-6 col-md-12 col-sm-12">
                <div class="info-content-area">
                    <div class="section-title pt-5 py-md-0" id="test">
                        <h6>{{post.subTitle}}</h6>
                        <h2 title="test">{{post.title}}</h2>
                    </div>
                    <div class="py-3 postContent" v-html="post.content"></div>
                    <a v-if="post.link" :href="post.link" class="main-btn">{{post.linkText}} <i class="fas fa-arrow-right"></i></a>
                </div>
            </div>
        </article>
    </div>
</template>

<script>
    export default {
        name: 'TextContent',
        data() {
            return {
                posts: [
                    {
                        id: 1,
                        title: "Wij zijn het interieur bureau van Eindhoven",
                        subTitle: "Over ons",
                        content: "<p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>",
                        img: {
                            image: 'http://capricorn-theme.net/html/archipix/assets/img/info/about.jpg',
                            alt: 'Peeters Interiors'
                        },
                        link: "about.html",
                        linkText: "Meer informatie"
                    },
                    {
                        id: 2,
                        title: "Wij zijn het interieur bureau van Eindhoven 2",
                        subTitle: "Over ons",
                        content: "<p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>",
                        img: {
                            image: 'http://capricorn-theme.net/html/archipix/assets/img/info/about.jpg',
                            alt: 'Peeters Interiors'
                        },
                        link: "about.html",
                        linkText: "Meer informatie"
                    }
                ],
            }
        },
        created() {
            // get current route
            console.log(this.$router.currentRoute.path);
        }
    }
</script>