<template>
  <div class="home">
    <HomeSlider/>
    <InfoSlider title="Wij zijn een <b>top</b> interieur bedrijf met een passie voor design."/>
    <TextWidget></TextWidget>
    <FeaturedPortfolio></FeaturedPortfolio>
    <ContactForm></ContactForm>
    <FooterPeeters></FooterPeeters>
  </div>
</template>

<script>
// @ is an alias to /src
import HomeSlider from '@/components/HomeSlider.vue'
import InfoSlider from '@/components/InfoSlider.vue'
import TextWidget from '@/components/TextWidget.vue'
import ContactForm from '@/components/ContactForm.vue'
import FooterPeeters from '@/components/Footer.vue'
import FeaturedPortfolio from '@/components/FeaturedPortfolio.vue'



export default {
  name: 'Home',
  components: {
    HomeSlider,
    InfoSlider,
    TextWidget,
    ContactForm,
    FooterPeeters,
    FeaturedPortfolio,
  },
  mounted() {
    const el = document.body;
    el.classList.remove('removeClass', 'noScroll');
  },
}
</script>

    